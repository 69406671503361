import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaBedPulse,FaUserDoctor } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react'
import { useMediaQuery } from 'react-responsive'
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 999px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
const mnu={
  border:'none',
  color:'black',
  fontWeight:'600',
  paddingRight:'1.0%',
  textAlign:'center',
  fontSize:'0.90em',
  cursor:'pointer'
}
const sldr={
  width:'1200px',
  height:'540px',
 
}
const mnu1={
  border:'none',
  color:'black',
  fontWeight:'600',
  paddingRight:'0.1%',
  textAlign:'center',
  fontSize:'1.41vw'
}
const ftr={
  height:'1%',
  padding:'0.2%',
  paddingTop:'0%',
  boxShadow:'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;',
  backgroundImage:'linear-gradient(0deg, #C2D9FF,#C2D9FF)',
  fontWeight:'600',
  textAlign:'left',
  fontSize:'0.99rem',
  width:'100%'
  }
  const ftr_tb={
    height:'1%',
    padding:'0.2%',
    paddingTop:'0%',
    boxShadow:'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;',
    backgroundImage:'linear-gradient(0deg, #C2D9FF,#C2D9FF)',
    fontWeight:'600',
    textAlign:'left',
    fontSize:'0.85rem',
    width:'100%'
    }
  const ftr3={
    textDecoration:'underline',
    color:'white',
    border:'none',
    backgroundImage:'linear-gradient(180deg, #0E2954,#0E2954)'
  }
  const ftr2={
    color:'white',
    height:'500px',
    padding:'0.2%',
    paddingTop:'0%',
    boxShadow:'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;',
    backgroundImage:'linear-gradient(180deg, #0E2954,#0E2954)',
    fontWeight:'600',
    textAlign:'left',
    fontSize:'0.99rem',
    width:'100%'
    }
    const ftr2_tb={
      color:'white',
      height:'auto',
      padding:'0.2%',
      paddingTop:'0%',
      boxShadow:'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;',
      backgroundImage:'linear-gradient(180deg, #0E2954,#0E2954)',
      fontWeight:'600',
      textAlign:'left',
      fontSize:'0.89rem',
      width:'100%'
      }
      const ftr2_tbl={
        color:'white',
        height:'auto',
        padding:'0.2%',
        paddingTop:'0%',
        boxShadow:'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;',
        backgroundImage:'linear-gradient(180deg, #0E2954,#0E2954)',
        fontWeight:'600',
        textAlign:'left',
        fontSize:'0.89rem',
        width:'100%'
        }
  const header_style={
height:'1%',
padding:'0.2%',
paddingTop:'0%',
boxShadow:'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;',
backgroundImage:'linear-gradient(0deg, #C2D9FF,#C2D9FF)',
fontWeight:'600',
textAlign:'left',
fontSize:'0.99rem',
width:'100%'
}
const hdr2={
  height:'5%',
  padding:'0.9%',
  backgroundImage:'linear-gradient(0deg, #F1F6F9,#F1F6F9)',
  boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
}
const header_style1={
  height:'1%',
  padding:'0.2%',
  paddingTop:'0%',
  boxShadow:'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;',
  backgroundImage:'linear-gradient(0deg, #C2D9FF,#C2D9FF)',
  fontWeight:'600',
  textAlign:'left',
  fontSize:'0.99rem',
  
  }

const header_c1={
  textAlign:'left',
  color:'black'
}
const cht={
  borderRadius:'1%',
backgroundImage:'linear-gradient(90deg, #FFFFFF,#FFFFFF)',
  boxShadow:'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px',
  width:'15%',
  float:'left',
  zIndex:'3999 !important',
  position:'fixed',
  bottom:'0.4%',
  left:'84%',
  height:'auto',
  color:'black',
  fontWeight:'700'
}
const cht_mn={
  visibility:'hidden',
  display:'none',
  maxHeight:'auto',
  padding:'1%',
  boxShadow:'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
  width:'15%',
  float:'left',
  zIndex:'15999 !important',
  position:'fixed',
  bottom:'0.4%',
  left:'84%',
  height:'auto',
  color:'black',
  fontWeight:'700',
  backgroundImage:'linear-gradient(90deg, #FFFFFF,#FFFFFF)',
}

const cht_lst={
  textAlign:'left',
  zIndex:'15999 !important',

}
const cht_tb={
  borderRadius:'1%',
backgroundImage:'linear-gradient(90deg, #FFFFFF,#FFFFFF)',
  boxShadow:'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px',
  width:'24%',
  float:'left',
  zIndex:'3999 !important',
  position:'fixed',
  bottom:'0.1%',
  left:'75%',
  height:'auto',
  color:'black',
  fontWeight:'700',
  fontSize:'0.78em'
}
const cht_mn_tb={
  visibility:'hidden',
  display:'none',
  maxHeight:'auto',
  padding:'1%',
  boxShadow:'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
  width:'20%',
  float:'left',
  zIndex:'15999 !important',
  position:'fixed',
  bottom:'0.4%',
  left:'77.6%',
  height:'auto',
  color:'black',
  fontWeight:'700',
  backgroundImage:'linear-gradient(90deg, #FFFFFF,#FFFFFF)',
  fontSize:'0.85em'
}
const cht_mn_mb={
  visibility:'hidden',
  display:'none',
  maxHeight:'auto',
  padding:'1%',
  boxShadow:'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
  width:'30%',
  float:'left',
  zIndex:'15999 !important',
  position:'fixed',
  bottom:'0.4%',
  left:'65.6%',
  height:'auto',
  color:'black',
  fontWeight:'700',
  backgroundImage:'linear-gradient(90deg, #FFFFFF,#FFFFFF)',
  fontSize:'0.75em'
}
const mnu_mid1={
  fontSize:'1.3em',
  border:'none',
  cursor:'pointer',
  backgroundImage:'linear-gradient(0deg, #C2D9FF,#C2D9FF)'
}
const mnu_mid2={
  fontSize:'1.3em',
  border:'none',
  cursor:'pointer',
  backgroundImage:'linear-gradient(0deg, #F3F8FF,#F0F3FF)'
}
const mnu_mid3={
  fontSize:'1.3em',
  border:'none',
  cursor:'pointer',
  backgroundImage:'linear-gradient(0deg, #F3F8FF,#F0F3FF)'
}
const mnu_midd1={
  fontSize:'0.99em',
  border:'none',
  cursor:'pointer',
  backgroundImage:'linear-gradient(0deg, #C2D9FF,#C2D9FF)'
}
const mnu_midd2={
  fontSize:'0.99em',
  border:'none',
  cursor:'pointer',
  backgroundImage:'linear-gradient(0deg, #F3F8FF,#F0F3FF)'
}
const mnu_midd3={
  fontSize:'0.99em',
  border:'none',
  cursor:'pointer',
  backgroundImage:'linear-gradient(0deg, #F3F8FF,#F0F3FF)'
}
const md_bdy1={
  padding:'1%',
  height:'auto',
  width:'100%',
  visibility:'visible',
  display:'inline-block',
  boxShadow:'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
}

const md_bdy2={
  padding:'1%',
  height:'auto',
  width:'100%',
  visibility:'hidden',
  display:'none',
  boxShadow:'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
}
const md_bdy3={
  padding:'1%',
  height:'auto',
  width:'100%',
  visibility:'hidden',
  display:'none',
  boxShadow:'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
}
const md_bdg={
  fontWeight:'500',
  backgroundImage:'linear-gradient(0deg, #F3F8FF,#F0F3FF)',
  fontSize:'1.3em',
  color:'black',
  padding:'1%',
  boxShadow:'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'
}
const md_bdg2={
  fontSize:'0.99em',
  padding:'1%',
  color:'red'
}
const md_bdg3={
  fontSize:'0.90em',
  padding:'1%',
  color:'blue'
}
const md_bddg={
  fontWeight:'500',
  backgroundImage:'linear-gradient(0deg, #F3F8FF,#F0F3FF)',
  fontSize:'0.99em',
  color:'black',
  padding:'1%',
  boxShadow:'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'
}
const md_bddg2={
  fontSize:'0.85em',
  padding:'1%',
  color:'red'
}
const md_bddg3={
  fontSize:'0.80em',
  padding:'1%',
  color:'blue'
}
const [isLoaded, setIsLoaded] = useState(false);
const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps

useEffect(() => {
    setIsLoaded(true);
}, []);

useEffect(() => {//on load
    if (isLoaded) {
     document.getElementById("cht_bx").style.bottom='2%';
     document.getElementById("cht_bx").style.backgroundImage='linear-gradient(180deg, #FFD93D,#FFD93D)';
     var delayInMilliseconds = 1000; //1 second
    
     setTimeout(function() {
       //your code to be executed after 1 second
       document.getElementById("cht_bx").style.bottom='0.4%';
       document.getElementById("cht_bx").style.backgroundImage='linear-gradient(90deg, #FFFFFF,#FFFFFF)';
     }, delayInMilliseconds);
 
    }
}, [isLoaded]);
function cht_anim(e){
e.preventDefault();
document.getElementById("cht_bx").style.textDecoration='underline';
document.getElementById("cht_bx").style.cursor='pointer';
return false;
}
function cht_anim2(e){
  e.preventDefault();
  document.getElementById("cht_bx").style.textDecoration='none';
  return false;
  }
  function cht_pl(e){
    e.preventDefault();
    if(document.getElementById("cht_bdy").style.visibility=='hidden'){
    document.getElementById("cht_bdy").style.visibility='visible';
  document.getElementById("cht_bdy").style.display='inline-block';
  document.getElementById("cht_i").style.visibility='hidden';
    document.getElementById("cht_i").style.display='none';
    var height = $(window).scrollTop();
    if(height==""){
    $('html, body').animate({
      scrollTop: $("#sb").offset().top - 140
  }, 2000);
}
  }

   return false;
  }
 function cht_cls(e){
e.preventDefault();
document.getElementById("cht_i").style.visibility='visible';
document.getElementById("cht_i").style.display='inline-block';
document.getElementById("cht_bdy").style.visibility='hidden';
  document.getElementById("cht_bdy").style.display='none';
return false;
 }
 function mo1(e){
   e.preventDefault();
   document.getElementsByClassName("tzd")[0].style.fontWeight='600';
   return false;
 }
 function om1(e){
  e.preventDefault();
  document.getElementsByClassName("tzd")[0].style.fontWeight='500';
  return false;
}
function mo2(e){
  e.preventDefault();
  document.getElementsByClassName("tzd")[1].style.fontWeight='600';
  return false;
}
function om2(e){
 e.preventDefault();
 document.getElementsByClassName("tzd")[1].style.fontWeight='500';
 return false;
}
function mo3(e){
  e.preventDefault();
  document.getElementsByClassName("tzd")[2].style.fontWeight='600';;
  return false;
}
function om3(e){
 e.preventDefault();
 document.getElementsByClassName("tzd")[2].style.fontWeight='500';
 return false;
}
function zz1(e){
  e.preventDefault();
  document.getElementsByClassName("tzdd")[0].style.visibility='visible';
  document.getElementsByClassName("tzdd")[0].style.display='inline-block';
  document.getElementsByClassName("tzdd")[1].style.visibility='hidden';
  document.getElementsByClassName("tzdd")[1].style.display='none';
  document.getElementsByClassName("tzdd")[2].style.visibility='hidden';
  document.getElementsByClassName("tzdd")[2].style.display='none';
  document.getElementsByClassName("tzd")[1].style.backgroundImage='linear-gradient(0deg, #F3F8FF,#F0F3FF)';
  document.getElementsByClassName("tzd")[0].style.backgroundImage='linear-gradient(0deg, #C2D9FF,#C2D9FF)';
  document.getElementsByClassName("tzd")[2].style.backgroundImage='linear-gradient(0deg, #F3F8FF,#F0F3FF)';
  return false;
}
function zz2(e){
  e.preventDefault();
  document.getElementsByClassName("tzdd")[0].style.visibility='hidden';
  document.getElementsByClassName("tzdd")[0].style.display='none';
  document.getElementsByClassName("tzdd")[1].style.visibility='visible';
  document.getElementsByClassName("tzdd")[1].style.display='inline-block';
  document.getElementsByClassName("tzdd")[2].style.visibility='hidden';
  document.getElementsByClassName("tzdd")[2].style.display='none';
  document.getElementsByClassName("tzd")[0].style.backgroundImage='linear-gradient(0deg, #F3F8FF,#F0F3FF)';
  document.getElementsByClassName("tzd")[1].style.backgroundImage='linear-gradient(0deg, #C2D9FF,#C2D9FF)';
  document.getElementsByClassName("tzd")[2].style.backgroundImage='linear-gradient(0deg, #F3F8FF,#F0F3FF)';
  return false;
}
function zz3(e){
  e.preventDefault();
  document.getElementsByClassName("tzdd")[0].style.visibility='hidden';
  document.getElementsByClassName("tzdd")[0].style.display='none';
  document.getElementsByClassName("tzdd")[1].style.visibility='hidden';
  document.getElementsByClassName("tzdd")[1].style.display='none';
  document.getElementsByClassName("tzdd")[2].style.visibility='visible';
  document.getElementsByClassName("tzdd")[2].style.display='inline-block';
  document.getElementsByClassName("tzd")[0].style.backgroundImage='linear-gradient(0deg, #F3F8FF,#F0F3FF)';
  document.getElementsByClassName("tzd")[2].style.backgroundImage='linear-gradient(0deg, #C2D9FF,#C2D9FF)';
  document.getElementsByClassName("tzd")[1].style.backgroundImage='linear-gradient(0deg, #F3F8FF,#F0F3FF)';
  return false;
}
  function m1(e){
    e.preventDefault();
    JSAlert.alert("ssj");
    return false;
  }
  return (
    <div className="App">
    {isDesktopOrLaptop && (
      <CContainer  fluid>
      <CHeader  style={header_style}>
      <CContainer fluid>
        <CRow>
        <CCol lg={4} xl={4} xxl={4}>
        <CNav as="nav">
 <CNavLink href="#" style={header_c1}><TiSocialFacebookCircular /></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialInstagramCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialLinkedinCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiTwitterXFill/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiYoutubeLine /></CNavLink>
</CNav>
        </CCol>
        <CCol lg={2} xl={2} xxl={2} style={{visibility:'hidden'}}>
        a
        </CCol>
        <CCol lg={6} xl={6} xxl={6}>
        <CNav as="nav">
 <CNavLink href="#" style={header_c1}><MdOutlinePrivacyTip/>Privacy Code</CNavLink>
 <CNavLink href="#" style={header_c1}><MdContactSupport/>Contact</CNavLink>
 <CNavLink href="#" style={header_c1}><MdOutlineAddBusiness/>Clause</CNavLink>
 <CNavLink href="#" style={header_c1}><MdOutlineFeedback/>Feedback</CNavLink>
 </CNav>
        </CCol>
</CRow>
</CContainer>
      </CHeader>
      <CContainer fluid style={hdr2}>
      <CRow>
      <CCol lg={3} xl={3} xxl={3} style={{textAlign:'left'}}>
      <CImage fluid rounded thumbnail src="https://lifelinehealth.in/image/logo.jpg" width={50} height={50} />
      <CBadge textBgColor="light">Lifeline<br/> Multi-Speciality <br/>Hospital</CBadge>
      </CCol>
       

      <CCol lg={6} xl={6} xxl={6} style={{textAlign:'left'}}>
      <CInputGroup className="flex-nowrap">
  <CFormInput style={{backgroundImage:'linear-gradient(90deg, #D2E9E9,#E3F4F4)',boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em'}} placeholder="Search Doctor or Hospital or Procedures or Manage-Booking or Hire-Ambulance" aria-label="Username" aria-describedby="addon-wrapping" type="search" />
  <CInputGroupText id="addon-wrapping" style={{backgroundImage:'linear-gradient(0deg, #495464,#495464)',color:'white'}}><FaSearchPlus/></CInputGroupText>
</CInputGroup>
      </CCol>
      <CCol lg={3} xl={3} xxl={3} style={{textAlign:'right'}}>
      <CRow>
      <CCol lg={6} xl={6} xxl={6}><CBadge textBgColor="light"><MdOutlineEmergency/>Emergency</CBadge><CButton color="primary" variant="outline" size="md" style={{backgroundImage:'linear-gradient(90deg, #D2E9E9,#E3F4F4)'}}>9999<MdAddCall/></CButton></CCol>
      <CCol lg={6} xl={6} xxl={6}><CBadge textBgColor="light"><FaHandHoldingHeart/>Lifeline</CBadge><CButton color="primary" variant="outline" size="md" style={{backgroundImage:'linear-gradient(90deg, #D2E9E9,#E3F4F4)'}}>9999<MdOutlineWifiCalling3/></CButton></CCol>
      </CRow>
      </CCol>
      </CRow>
      </CContainer>
      <CContainer fluid style={{padding:'1%'}}>
      <CRow>
      <CCol lg={1} xl={1} xxl={1} style={{visibility:'hidden'}}>
        a
      </CCol>
        <CCol lg={11} xl={11} xxl={11}>
      <CListGroup className="sm-4" layout={`horizontal`}>
      <CListGroupItem style={mnu} on>Patient<br/>Cure<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu}>Medical<br/>Departments<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu}>Health<br/>Bulletin<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu}>Hospital<br/>Network<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu}>Research &<br/>Development<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu}>Connect-with<br/>Us<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu}>Job<br/>Portal<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu}>Latest<br/>Facilities<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu}>Hire<br/>Ambulance<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu}>Home<br/>Care<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu}>Tie<br/>Ups<FaCaretDown/></CListGroupItem> 
    </CListGroup>
    </CCol>
   
    </CRow>
      </CContainer>
      <CContainer fluid style={{padding:'0.5%', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
       <CRow>
         <CCol lg={12} xl={12} xxl={12}>
         <CCarousel  controls indicators dark>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i4.jpeg"} alt="slide 1" style={sldr} />
    <CCarouselCaption className="d-none d-md-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Premier Multi Speciality Hospital</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i5.jpeg"} alt="slide 2" style={sldr} />
    <CCarouselCaption className="d-none d-md-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>State of Art Facilities</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i6.jpeg"} alt="slide 3" style={sldr} />
    <CCarouselCaption className="d-none d-md-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Cost effective Health-Care</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i7.jpeg"} alt="slide 4" style={sldr} />
    <CCarouselCaption className="d-none d-md-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Expert Medical Advice</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i8.jpeg"} alt="slide 5" style={sldr} />
    <CCarouselCaption className="d-none d-md-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Maternity and Neonatal Care</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i9.jpeg"} alt="slide 6" style={sldr} />
    <CCarouselCaption className="d-none d-md-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Emergency Department (ED)</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i10.jpeg"} alt="slide 7" style={sldr} />
    <CCarouselCaption className="d-none d-md-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Inpatient and Outpatient Services</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i11.jpeg"} alt="slide 8" style={sldr} />
    <CCarouselCaption className="d-none d-md-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Intensive Care Unit (ICU)</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i12.jpeg"} alt="slide 9" style={sldr} />
    <CCarouselCaption className="d-none d-md-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Operating Rooms (OT)</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i13.jpeg"} alt="slide 10" style={sldr} />
    <CCarouselCaption className="d-none d-md-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Specialty Departments</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i14.jpeg"} alt="slide 11" style={sldr} />
    <CCarouselCaption className="d-none d-md-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Rehabilitation Services</h5>
    </CCarouselCaption>
  </CCarouselItem>
</CCarousel>
         </CCol>
       </CRow>
      </CContainer>
      <CContainer id="sb" fluid style={{padding:'1%',paddingLeft:'4%',paddingRight:'4%'}}>
      <CRow>
      <CCol lg={3} xl={3} xxl={3} style={{textAlign:'center'}}>
      <CWidgetStatsA
      className="sm-4"
      style={{backgroundImage:'linear-gradient(90deg, #F6F5F2,#F0EBE3,#F3D0D7,#FFEFEF)',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}
      value={
        <>
        <CContainer fluid style={{fontSize:'0.90em',textAlign:'center'}}>
        <GrScheduleNew /><br/>
          Book<br/>Appointment
          </CContainer>
        </>
      }
      />
      </CCol>
      <CCol lg={3} xl={3} xxl={3} style={{textAlign:'center'}}>
      <CWidgetStatsA
      className="sm-4"
      style={{backgroundImage:'linear-gradient(90deg, #F6F5F2,#F0EBE3,#F3D0D7,#FFEFEF)',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}
      value={
        <>
        <CContainer fluid style={{fontSize:'0.90em',textAlign:'center'}}>
        <GiHealthPotion /><br/>
          Health<br/>Checkup
          </CContainer>
        </>
      }
      />
      </CCol>
      <CCol lg={3} xl={3} xxl={3} style={{textAlign:'center'}}>
      <CWidgetStatsA
      className="sm-4"
      style={{backgroundImage:'linear-gradient(90deg, #F6F5F2,#F0EBE3,#F3D0D7,#FFEFEF)',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}
      value={
        <>
        <CContainer fluid style={{fontSize:'0.90em',textAlign:'center'}}>
        <MdOnlinePrediction /><br/>
          Online<br/>Consultancy
          </CContainer>
        </>
      }
      />
      </CCol>
     
      <CCol lg={3} xl={3} xxl={3} style={{textAlign:'center'}}>
      <CWidgetStatsA
      className="sm-4"
      style={{backgroundImage:'linear-gradient(90deg, #F6F5F2,#F0EBE3,#F3D0D7,#FFEFEF)',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}
      value={
        <>
        <CContainer fluid style={{fontSize:'0.90em',textAlign:'center'}}>
        <MdLocalPharmacy /><br/>
          Purchase<br/>Pharmacy
          </CContainer>
        </>
      }
      />
      </CCol>
      </CRow>
      </CContainer>
      <CContainer fluid style={{height:'auto',width:'100%',padding:'1%',boxShadow:'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset'}}>
       <CRow>
           <CCol lg={12} xl={12} xxl={12} style={{fontSize:'0.90em',textAlign:'center'}}>
           <h4><span style={{backgroundImage:'linear-gradient(90deg, #E3F6FF,#E3F6FF)'}}>Nurturing Health Care</span></h4>
           </CCol>
         </CRow>
         <CContainer style={{padding:'1%',paddingLeft:'28%'}}>
         <CListGroup className="sm-4" layout={`horizontal`}>
         <CListGroupItem className="tzd" style={mnu_mid1} onClick={zz1} onMouseOver={mo1} onMouseOut={om1}><FaHandHoldingMedical style={{color:'#C40C0C'}}/> Departments</CListGroupItem>
         <CListGroupItem className="tzd" style={mnu_mid2} onClick={zz2} onMouseOver={mo2} onMouseOut={om2}><FaHospitalAlt style={{color:'#C40C0C'}}/> Facilities</CListGroupItem>
         <CListGroupItem className="tzd" style={mnu_mid3} onClick={zz3} onMouseOver={mo3} onMouseOut={om3}><FaUserDoctor style={{color:'#C40C0C'}}/> Faculty</CListGroupItem>
         </CListGroup>
         </CContainer>
         <CContainer fluid className="tzdd" style={md_bdy1}>
         <CRow>
           <CCol  lg={5} xl={5} xxl={5}>
           <CImage  fluid rounded src="https://lifelinehealth.in/image/department.jpg"/>
           </CCol>
           <CCol  lg={7} xl={7} xxl={7}>
           <CContainer fluid style={{padding:'1%'}}>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill"> Obstetrics &<br/> Gynaecology</CBadge>
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill"> Orthopedics &<br/> spine</CBadge> 
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">General Surgery</CBadge>
           </CCol>
           
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">Gastroenterology</CBadge>
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">Pediatrics</CBadge>
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">Neurology</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">Urology</CBadge>
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">Oncology</CBadge>
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">Plastic Surgery</CBadge>
           </CCol>
          
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">Cardiology</CBadge>
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">Dental</CBadge>
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">Psychology</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  lg={4} xl={4} xxl={4} style={{visibility:'hidden'}}>
           a
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">ENT</CBadge>
           </CCol>
           <CCol  lg={4} xl={4} xxl={4} style={{visibility:'hidden'}}>
         a
           </CCol>
           </CRow>
           </CContainer>
           </CContainer>
           </CCol>
         </CRow>
         </CContainer>
      <CContainer fluid className="tzdd" style={md_bdy2}>
      <CRow>
           <CCol  lg={5} xl={5} xxl={5}>
           <CImage  fluid rounded src="https://lifelinehealth.in/image/facility.jpg"/>
           </CCol>
           <CCol  lg={7} xl={7} xxl={7}>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">ICU</CBadge>
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">NICU</CBadge>
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">Ventilator</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">Pathology</CBadge>
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">Physiotherapy</CBadge>
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">Dietitians</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">X-Ray</CBadge>
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">ECG</CBadge>
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">Pharmacy</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  lg={4} xl={4} xxl={4} style={{visibility:'hidden'}}>
           a
           </CCol>
           <CCol  lg={4} xl={4} xxl={4}>
           <CBadge style={md_bdg} shape="rounded-pill">Ultrasound</CBadge>
           </CCol>
           <CCol  lg={4} xl={4} xxl={4} style={{visibility:'hidden'}}>
         a
           </CCol>
           </CRow>
           </CContainer>
           </CCol>
           </CRow>
      </CContainer>
      <CContainer fluid className="tzdd" style={md_bdy3}>
      <CRow>
         
           <CCol  lg={12} xl={12} xxl={12}>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol  lg={3} xl={3} xxl={3}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bdg} shape="rounded-pill">
            Dr. Sashank Nigam
            </CBadge>
            <CBadge style={md_bdg2} shape="rounded-pill">
           MS,DNB,MRCS,MNAMS
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bdg3}>Fellowship, Surgical Oncology,</CBadge>
            
            <CBadge style={md_bdg3}>Oncoplastic Breast Surgery</CBadge>
            
            </CListGroupItem>
              </CListGroup>
           </CCol>
           <CCol  lg={3} xl={3} xxl={3}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bdg} shape="rounded-pill">
            Dr. Dharamvir Singh
            </CBadge>
            <CBadge style={md_bdg2} shape="rounded-pill">
          MBBS,MS,MCH(Urology)
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bdg3}>Urologist,</CBadge>
            <CBadge style={md_bdg3}>Laproscopic,</CBadge>
            <CBadge style={md_bdg3}>Transplant Surgeon</CBadge>
            </CListGroupItem>
              </CListGroup>
           </CCol>
           <CCol  lg={3} xl={3} xxl={3}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bdg} shape="rounded-pill">
            Dr. Abhishek Verma
            </CBadge>
            <CBadge style={md_bdg2} shape="rounded-pill">
          MS(General Surgeon),FIAGES
            </CBadge>
           </CCol>
           <CCol  lg={3} xl={3} xxl={3}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bdg} shape="rounded-pill">
            Dr. Rohit Saxena
            </CBadge>
            <CBadge style={md_bdg2} shape="rounded-pill">
          MBBS,MS(Opthalmologist)
            </CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol  lg={3} xl={3} xxl={3}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bdg} shape="rounded-pill">
            Dr. Nitesh Singh Sodi
            </CBadge>
            <CBadge style={md_bdg2} shape="rounded-pill">
          Psychology D(Honors)
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bdg3}>Neuro-psycologist, Counsellor</CBadge>
            
            <CBadge style={md_bdg3}>& Psycotherapist</CBadge>
            
            </CListGroupItem>
              </CListGroup>
           </CCol>
           <CCol  lg={3} xl={3} xxl={3}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bdg} shape="rounded-pill">
            Dr. Harish Kumar Singh
            </CBadge>
            <CBadge style={md_bdg2} shape="rounded-pill">
          MBBS,MD
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bdg3}>Physician</CBadge>
            </CListGroupItem>
              </CListGroup>
           </CCol>
           <CCol  lg={3} xl={3} xxl={3}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bdg} shape="rounded-pill">
            Dr. Pranjal Saxena
            </CBadge>
            <CBadge style={md_bdg2} shape="rounded-pill">
          BDS(Dental Surgeon)
            </CBadge>
           
           </CCol>
           <CCol  lg={3} xl={3} xxl={3}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bdg} shape="rounded-pill">
            Dr. Harsh Vardhan
            </CBadge>
            <CBadge style={md_bdg2} shape="rounded-pill">
          BDS,Fage(Dental Surgeon)
            </CBadge>
           
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol  lg={3} xl={3} xxl={3}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bdg} shape="rounded-pill">
            Dr. Pankaj Pathak
            </CBadge>
            <CBadge style={md_bdg2} shape="rounded-pill">
         MDS(Oro-Maxicofasical)
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bdg3}>Consultant Oro</CBadge>
            
            <CBadge style={md_bdg3}>& Facial Trauma</CBadge>
            
            </CListGroupItem>
              </CListGroup>
           </CCol>
           <CCol  lg={3} xl={3} xxl={3}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bdg} shape="rounded-pill">
            Dr. Sudhanshu Verma
            </CBadge>
            <CBadge style={md_bdg2} shape="rounded-pill">
          MBBS(D.Cardiology)
            </CBadge>
           
           </CCol>
           <CCol  lg={3} xl={3} xxl={3}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_fem.png"} />
            <CBadge style={md_bdg} shape="rounded-pill">
            Dr. Ankita Singh
            </CBadge>
            <CBadge style={md_bdg2} shape="rounded-pill">
          MBBS,FIGO(Gynecologist)
            </CBadge>
           
           </CCol>
           <CCol  lg={3} xl={3} xxl={3}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_fem.png"} />
            <CBadge style={md_bdg} shape="rounded-pill">
            Dr. Nilima
            </CBadge>
            <CBadge style={md_bdg2} shape="rounded-pill">
          MBBS,MS
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bdg3}>Obstetrics</CBadge>
            
            <CBadge style={md_bdg3}>& Gynecologist</CBadge>
            
            </CListGroupItem>
              </CListGroup>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol  lg={3} xl={3} xxl={3} style={{visibility:'hidden'}}>
           a
           </CCol>
           <CCol  lg={3} xl={3} xxl={3}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bdg} shape="rounded-pill">
            Dr. Sharad Kumar Singh
            </CBadge>
            <CBadge style={md_bdg2} shape="rounded-pill">
          MBBS,MD(Pediatrics)
            </CBadge>
          
           </CCol>
           <CCol  lg={3} xl={3} xxl={3}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bdg} shape="rounded-pill">
            Dr. Arshad Khalid
            </CBadge>
            <CBadge style={md_bdg2} shape="rounded-pill">
          MBBS,MS(Anaesthosiology)
            </CBadge>
          
           </CCol>
           <CCol  lg={3} xl={3} xxl={3} style={{visibility:'hidden'}}>
           a
           </CCol>
           </CRow>
           </CContainer>
           </CCol>
           </CRow>
      </CContainer>
      
      </CContainer>
     
      <CFooter>
      <CContainer fluid style={ftr2}>
        <CRow>
        <CCol lg={3} xl={3} xxl={3} style={{textAlign:'center'}}>
        <CContainer fluid style={{height:'auto',padding:'1%',width:'100%',textAlign:'center'}}>
        <CImage fluid rounded thumbnail src="https://lifelinehealth.in/image/logo.jpg" width={150} height={150} />
        </CContainer>
        <CContainer fluid style={{height:'auto',padding:'1%',width:'100%',textAlign:'center'}}>
        <h5 style={{color:'#EE7214'}}>Dedicated to a healthy and hearty life!</h5>
          </CContainer>
          <CContainer fluid style={{height:'auto',padding:'1%',width:'100%',textAlign:'center'}}>
          <h5>Subscribe to our <FcNews />feed</h5>
          </CContainer>
          <CContainer fluid style={{height:'auto',padding:'1%',width:'100%',textAlign:'center'}}>
          <CFormInput style={{backgroundImage:'linear-gradient(90deg, #D2E9E9,#E3F4F4)',boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em'}} placeholder="Please enter your email" aria-label="Username" aria-describedby="addon-wrapping" type="text" />
          <br/>
          <CButton  size="lg" style={{width:'99%',backgroundColor:"#7ED7C1"}}><MdOutlineUnsubscribe />Subscribe</CButton>
          </CContainer>
          <br/>
          <CContainer fluid style={{textAlign:'left',padding:'1%',textDecoration:'underline'}}>
          <h5><MdAddCall/> +91-999999</h5>
          <br/>
          <h5><MdOutlineEmail/> lifeline@gmail.com</h5>
          </CContainer>
        </CCol>
        <CCol lg={9} xl={9} xxl={9} style={{textAlign:'center'}}>
        <CListGroup>
  <CListGroupItem style={ftr3}>Patient Cure</CListGroupItem>
  <CListGroupItem style={ftr3}>Medical Department</CListGroupItem>
  <CListGroupItem style={ftr3}>Health Bulletin</CListGroupItem>
  <CListGroupItem style={ftr3}>Hospital Network</CListGroupItem>
  <CListGroupItem style={ftr3}>Research & Development</CListGroupItem>
  <CListGroupItem style={ftr3}>Connect with Us</CListGroupItem>
  <CListGroupItem style={ftr3}>Job Portal</CListGroupItem>
  <CListGroupItem style={ftr3}>Latest Facilities</CListGroupItem>
  <CListGroupItem style={ftr3}>Hire Ambulance</CListGroupItem>
  <CListGroupItem style={ftr3}>Home Care</CListGroupItem>
  <CListGroupItem style={ftr3}>Tie Up</CListGroupItem>
</CListGroup>
        </CCol>
        </CRow>
      </CContainer>
        <CContainer fluid style={ftr}>
        <CRow>
        <CCol lg={4} xl={4} xxl={4}>
        <CNav as="nav">
 <CNavLink href="#" style={header_c1}><TiSocialFacebookCircular /></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialInstagramCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialLinkedinCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiTwitterXFill/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiYoutubeLine /></CNavLink>
</CNav>
        </CCol>
          <CCol lg={4} xl={4} xxl={4}>
  <div>
    <CLink href="https://coreui.io">@ Copyright 2024</CLink>
    <span>&copy; Lifeline Multi-Speciality.</span>
 
    <span style={{color:'#005B41'}}>All rights reserved.</span>
    
  </div>
  </CCol>
  <CCol>
  <CNav as="nav">
 <CNavLink href="#" style={header_c1}><MdOutlinePrivacyTip/>Privacy Code</CNavLink>
 <CNavLink href="#" style={header_c1}><MdContactSupport/>Contact</CNavLink>
 <CNavLink href="#" style={header_c1}><MdOutlineAddBusiness/>Clause</CNavLink>
 <CNavLink href="#" style={header_c1}><MdOutlineFeedback/>Feedback</CNavLink>
 </CNav>
  </CCol>
  </CRow>
  </CContainer>
</CFooter>
<CContainer id="cht_i" fluid style={cht} onMouseOver={cht_anim} onMouseOut={cht_anim2}>
 <CRow>
  <CCol  lg={3} xl={3} xxl={3} style={{textAlign:'left'}}>
<FaCommentMedical style={{color:'#C40C0C',fontSize:'30px'}} />
</CCol>
<CCol id="cht_bx"  lg={9} xl={9} xxl={9} style={{textAlign:'left'}}  onClick={cht_pl}>
<span>How may I help you?</span>
</CCol>
</CRow>
 </CContainer>
 <CContainer id="cht_bdy" style={cht_mn}>
 <CBadge color="danger" position="top-end" shape="rounded-pill" onClick={cht_cls} style={{cursor:'pointer'}}>
 <FaRegWindowClose />
 </CBadge>
 <CListGroup>
 <CListGroupItem onClick={m1} as="button" style={cht_lst}><FaCalendarCheck />Fix-Appointment</CListGroupItem>
 <CListGroupItem onClick={m1} as="button" style={cht_lst}><BsTelephonePlusFill/>Tele-Medicine</CListGroupItem>
 <CListGroupItem  onClick={m1} as="button" style={cht_lst}><MdVideoChat/>Video-Call</CListGroupItem>
<CListGroupItem  onClick={m1} as="button" style={cht_lst}><FaAmbulance/>Book-Ambulance</CListGroupItem>
<CListGroupItem  onClick={m1} as="button" style={cht_lst}><RiCustomerService2Fill/>Customer-Care</CListGroupItem>
<CListGroupItem  onClick={m1} as="button" style={cht_lst}><FaBedPulse/>Bed-Availibility</CListGroupItem>
</CListGroup>

</CContainer>
      </CContainer>
    )}
       {isTabletOrMobile && (
         <CContainer  fluid>
      <CHeader  style={header_style1}>
      <CContainer fluid>
        <CRow>
        <CCol sm={10} md={10} lg={10}>
        <CNav as="nav" style={header_c1}>
 <CNavLink href="#" style={header_c1}><TiSocialFacebookCircular /></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialInstagramCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialLinkedinCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiTwitterXFill/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiYoutubeLine /></CNavLink>

</CNav>
        </CCol>
        <CCol sm={2} md={2} lg={2}>
        <CDropdown>
  <CDropdownToggle href="#" color="#4CCD99"><RxHamburgerMenu/></CDropdownToggle>
  <CDropdownMenu>
    <CDropdownItem href="#"><MdOutlinePrivacyTip/>Privacy Code</CDropdownItem>
    <CDropdownItem href="#"><MdContactSupport/>Contact</CDropdownItem>
    <CDropdownItem href="#"><MdOutlineAddBusiness/>Clause</CDropdownItem>
    <CDropdownItem href="#"><MdOutlineFeedback/>Feedback</CDropdownItem>
  </CDropdownMenu>
</CDropdown>
        </CCol>
        
</CRow>
</CContainer>
      </CHeader>
      <CContainer fluid style={hdr2}>
      <CRow>
      <CCol sm={4} md={4} lg={4} style={{textAlign:'left'}}>
      <CImage fluid rounded thumbnail src="https://lifelinehealth.in/image/logo.jpg" width={50} height={50} />
      <CBadge textBgColor="light">Lifeline<br/> Multi-Speciality <br/>Hospital</CBadge>
      </CCol>
       
      
      <CCol sm={8} md={8} lg={8} style={{textAlign:'left'}}>
      <CInputGroup className="flex-nowrap">
  <CFormInput style={{backgroundImage:'linear-gradient(90deg, #D2E9E9,#E3F4F4)',boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em'}} placeholder="Search Doctor or Hospital or Procedures or Manage-Booking or Hire-Ambulance" aria-label="Username" aria-describedby="addon-wrapping" type="search" />
  <CInputGroupText id="addon-wrapping" style={{backgroundImage:'linear-gradient(0deg, #495464,#495464)',color:'white'}}><FaSearchPlus/></CInputGroupText>
</CInputGroup>
      
      </CCol>
     
      </CRow>
      <CRow>
      <CCol sm={12} md={12} lg={12} style={{textAlign:'right',padding:'1%'}}>
      <CBadge textBgColor="light"><MdOutlineEmergency/>Emergency</CBadge><CButton color="primary" variant="outline" size="sm"  style={{backgroundImage:'linear-gradient(90deg, #D2E9E9,#E3F4F4)'}}>9999<MdAddCall/></CButton>
      <CBadge textBgColor="light"><FaHandHoldingHeart/>Lifeline</CBadge><CButton color="primary" variant="outline" size="sm"  style={{backgroundImage:'linear-gradient(90deg, #D2E9E9,#E3F4F4)'}}>9999<MdAddCall/></CButton>
      </CCol>
      </CRow>
      </CContainer>
      <CContainer fluid style={{padding:'1%'}}>
      <CRow>
        <CCol sm={12} md={12} lg={12}>
      <CListGroup className="sm-4" layout={`horizontal`}>
      <CListGroupItem style={mnu1} on>Patient<br/>Cure<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu1}>Medical<br/>Departments<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu1}>Health<br/>Bulletin<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu1}>Hospital<br/>Network<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu1}>Research &<br/>Development<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu1}>Connect-with<br/>Us<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu1}>Job<br/>Portal<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu1}>Latest<br/>Facilities<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu1}>Hire<br/>Ambulance<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu1}>Home<br/>Care<FaCaretDown/></CListGroupItem>
      <CListGroupItem style={mnu1}>Tie<br/>Ups<FaCaretDown/></CListGroupItem> 
    </CListGroup>
    </CCol>
  
    </CRow>
      </CContainer>
      <CContainer fluid style={{padding:'0.5%', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
       <CRow>
         <CCol sm={12} md={12} lg={12}>
         <CCarousel  controls indicators dark>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i4.jpeg"} alt="slide 1" style={sldr} />
    <CCarouselCaption className="d-none d-sm-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Premier Multi Speciality Hospital</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i5.jpeg"} alt="slide 2" style={sldr} />
    <CCarouselCaption className="d-none d-sm-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>State of Art Facilities</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i6.jpeg"} alt="slide 3" style={sldr} />
    <CCarouselCaption className="d-none d-sm-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Cost effective Health-Care</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i7.jpeg"} alt="slide 4" style={sldr} />
    <CCarouselCaption className="d-none d-sm-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Expert Medical Advice</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i8.jpeg"} alt="slide 5" style={sldr} />
    <CCarouselCaption className="d-none d-sm-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Maternity and Neonatal Care</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i9.jpeg"} alt="slide 6" style={sldr} />
    <CCarouselCaption className="d-none d-sm-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Emergency Department (ED)</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i10.jpeg"} alt="slide 7" style={sldr} />
    <CCarouselCaption className="d-none d-sm-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Inpatient and Outpatient Services</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i11.jpeg"} alt="slide 8" style={sldr} />
    <CCarouselCaption className="d-none d-sm-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Intensive Care Unit (ICU)</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i12.jpeg"} alt="slide 9" style={sldr} />
    <CCarouselCaption className="d-none d-sm-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Operating Rooms (OT)</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i13.jpeg"} alt="slide 10" style={sldr} />
    <CCarouselCaption className="d-none d-sm-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Specialty Departments</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i14.jpeg"} alt="slide 11" style={sldr} />
    <CCarouselCaption className="d-none d-sm-block" style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Rehabilitation Services</h5>
    </CCarouselCaption>
  </CCarouselItem>
</CCarousel>
         </CCol>
       </CRow>
      </CContainer>
      <CContainer id="sb" fluid style={{padding:'1%',paddingLeft:'2%',paddingRight:'2%'}}>
      <CRow>
      <CCol sm={3} md={3} lg={3} style={{textAlign:'center'}}>
      <CWidgetStatsA
      className="sm-4"
      style={{backgroundImage:'linear-gradient(90deg, #F6F5F2,#F0EBE3,#F3D0D7,#FFEFEF)',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}
      value={
        <>
        <CContainer fluid style={{fontSize:'0.63em',textAlign:'center'}}>
        <GrScheduleNew /><br/>
          Book<br/>Appointment
          </CContainer>
        </>
      }
      />
      </CCol>
      <CCol sm={3} md={3} lg={3} style={{textAlign:'center'}}>
      <CWidgetStatsA
      className="sm-4"
      style={{backgroundImage:'linear-gradient(90deg, #F6F5F2,#F0EBE3,#F3D0D7,#FFEFEF)',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}
      value={
        <>
        <CContainer fluid style={{fontSize:'0.63em',textAlign:'center'}}>
        <GiHealthPotion /><br/>
          Health<br/>Checkup
          </CContainer>
        </>
      }
      />
      </CCol>
      <CCol sm={3} md={3} lg={3} style={{textAlign:'center'}}>
      <CWidgetStatsA
      className="sm-4"
      style={{backgroundImage:'linear-gradient(90deg, #F6F5F2,#F0EBE3,#F3D0D7,#FFEFEF)',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}
      value={
        <>
        <CContainer fluid style={{fontSize:'0.63em',textAlign:'center'}}>
        <MdOnlinePrediction /><br/>
          Online<br/>Consultancy
          </CContainer>
        </>
      }
      />
      </CCol>
     
      <CCol sm={3} md={3} lg={3} style={{textAlign:'center'}}>
      <CWidgetStatsA
      className="sm-4"
      style={{backgroundImage:'linear-gradient(90deg, #F6F5F2,#F0EBE3,#F3D0D7,#FFEFEF)',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}
      value={
        <>
        <CContainer fluid style={{fontSize:'0.63em',textAlign:'center'}}>
        <MdLocalPharmacy /><br/>
          Purchase<br/>Pharmacy
          </CContainer>
        </>
      }
      />
      </CCol>
      </CRow>
      </CContainer>
      <CContainer fluid style={{height:'auto',width:'100%',padding:'1%',boxShadow:'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset'}}>
       <CRow>
           <CCol sm={12} md={12} lg={12} style={{fontSize:'0.90em',textAlign:'center'}}>
           <h4><span style={{backgroundImage:'linear-gradient(90deg, #E3F6FF,#E3F6FF)'}}>Nurturing Health Care</span></h4>
           </CCol>
         </CRow>
         <CContainer style={{padding:'1%',paddingLeft:'24%'}}>
         <CListGroup className="sm-4" layout={`horizontal`}>
         <CListGroupItem className="tzd" style={mnu_midd1} onClick={zz1} onMouseOver={mo1} onMouseOut={om1}><FaHandHoldingMedical style={{color:'#C40C0C'}}/> Departments</CListGroupItem>
         <CListGroupItem className="tzd" style={mnu_midd2} onClick={zz2} onMouseOver={mo2} onMouseOut={om2}><FaHospitalAlt style={{color:'#C40C0C'}}/> Facilities</CListGroupItem>
         <CListGroupItem className="tzd" style={mnu_midd3} onClick={zz3} onMouseOver={mo3} onMouseOut={om3}><FaUserDoctor style={{color:'#C40C0C'}}/> Faculty</CListGroupItem>
         </CListGroup>
         </CContainer>
         <CContainer fluid className="tzdd" style={md_bdy1}>
         <CRow>
           <CCol  sm={5} md={5} lg={5}>
           <CImage  fluid rounded src="https://lifelinehealth.in/image/department.jpg"/>
           </CCol>
           <CCol  sm={7} md={7} lg={7}>
           <CContainer fluid style={{padding:'1%'}}>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  sm={6} md={6} lg={6}>
           <CBadge style={md_bddg} shape="rounded-pill"> Obstetrics &<br/> Gynaecology</CBadge>
           </CCol>
           <CCol  sm={6} md={6} lg={6}>
           <CBadge style={md_bddg} shape="rounded-pill"> Orthopedics &<br/> spine</CBadge> 
           </CCol>
          </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  sm={6} md={6} lg={6}>
           <CBadge style={md_bddg} shape="rounded-pill">General Surgery</CBadge>
           </CCol>
           <CCol  sm={6} md={6} lg={6}>
           <CBadge style={md_bddg} shape="rounded-pill">Gastroenterology</CBadge>
           </CCol>
          
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">Pediatrics</CBadge>
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">ENT</CBadge>
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">Neurology</CBadge>
           </CCol>
          
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">Urology</CBadge>
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">Oncology</CBadge>
           </CCol>          
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">Cardiology</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">Plastic Surgery</CBadge>
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">Dental</CBadge>
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">Psychology</CBadge>
           </CCol>
                    </CRow>
           </CContainer>
         
           </CContainer>
           </CCol>
         </CRow>
         </CContainer>
      <CContainer fluid className="tzdd" style={md_bdy2}>
      <CRow>
           <CCol  sm={5} md={5} lg={5}>
           <CImage  fluid rounded src="https://lifelinehealth.in/image/facility.jpg"/>
           </CCol>
           <CCol  sm={7} md={7} lg={7}>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">ICU</CBadge>
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">NICU</CBadge>
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">Ventilator</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">Pathology</CBadge>
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">Physiotherapy</CBadge>
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">Dietitians</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">X-Ray</CBadge>
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">ECG</CBadge>
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">Pharmacy</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  sm={4} md={4} lg={4} style={{visibility:'hidden'}}>
           a
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CBadge style={md_bddg} shape="rounded-pill">Ultrasound</CBadge>
           </CCol>
           <CCol  sm={4} md={4} lg={4} style={{visibility:'hidden'}}>
         a
           </CCol>
           </CRow>
           </CContainer>
           </CCol>
           </CRow>
      </CContainer>
      <CContainer fluid className="tzdd" style={md_bdy3}>
      <CRow>
         
           <CCol  sm={12} md={12} lg={12}>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol  sm={4} md={4} lg={4}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Sashank Nigam
            </CBadge>
            <CBadge style={md_bddg2} shape="rounded-pill">
           MS,DNB,MRCS,MNAMS
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bddg3}>Fellowship, Surgical Oncology,</CBadge>
            
            <CBadge style={md_bddg3}>Oncoplastic Breast Surgery</CBadge>
            
            </CListGroupItem>
              </CListGroup>
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Dharamvir Singh
            </CBadge>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS,MS,MCH(Urology)
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bddg3}>Urologist,</CBadge>
            <CBadge style={md_bddg3}>Laproscopic,</CBadge>
            <CBadge style={md_bddg3}>Transplant Surgeon</CBadge>
            </CListGroupItem>
              </CListGroup>
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Abhishek Verma
            </CBadge>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MS(General Surgeon),<br/>FIAGES
            </CBadge>
           </CCol>
          
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol  sm={4} md={4} lg={4}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Rohit Saxena
            </CBadge>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS,MS(Opthalmologist)
            </CBadge>
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Nitesh Singh Sodi
            </CBadge>
            <CBadge style={md_bddg2} shape="rounded-pill">
          Psychology D(Honors)
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bddg3}>Neuro-psycologist</CBadge>
            
            <CBadge style={md_bddg3}>Counsellor & Psycotherapist</CBadge>
            
            </CListGroupItem>
              </CListGroup>
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Harish Kumar Singh
            </CBadge>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS,MD
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bddg3}>Physician</CBadge>
            </CListGroupItem>
              </CListGroup>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol  sm={4} md={4} lg={4}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Pranjal Saxena
            </CBadge>
            <CBadge style={md_bddg2} shape="rounded-pill">
          BDS(Dental Surgeon)
            </CBadge>
           
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Harsh Vardhan
            </CBadge>
            <CBadge style={md_bddg2} shape="rounded-pill">
          BDS,Fage(Dental Surgeon)
            </CBadge>
           
           </CCol>
          
           <CCol  sm={4} md={4} lg={4}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Pankaj Pathak
            </CBadge>
            <CBadge style={md_bddg2} shape="rounded-pill">
         MDS(Oro-Maxicofasical)
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bddg3}>Consultant Oro</CBadge>
            
            <CBadge style={md_bddg3}>& Facial Trauma</CBadge>
            
            </CListGroupItem>
              </CListGroup>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol  sm={4} md={4} lg={4}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Sudhanshu Verma
            </CBadge>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS(D.Cardiology)
            </CBadge>
           
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_fem.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Ankita Singh
            </CBadge>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS,FIGO(Gynecologist)
            </CBadge>
           
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_fem.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Nilima
            </CBadge>
            <br/>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS,MS
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bddg3}>Obstetrics</CBadge>
            
            <CBadge style={md_bddg3}>& Gynecologist</CBadge>
            
            </CListGroupItem>
              </CListGroup>
           </CCol>
          
          
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
             <CRow>
             <CCol  sm={2} md={2} lg={2} style={{visibility:'hidden'}}>
           a
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Sharad Kumar Singh
            </CBadge>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS,MD(Pediatrics)
            </CBadge>
          
           </CCol>
           <CCol  sm={4} md={4} lg={4}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Arshad Khalid
            </CBadge>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS,MS(Anaesthosiology)
            </CBadge>
          
           </CCol>
           <CCol  sm={2} md={2} lg={2} style={{visibility:'hidden'}}>
           a
           </CCol>
             </CRow>
           </CContainer>
           </CCol>
           </CRow>
      </CContainer>
      
      </CContainer>
      <CFooter>
      <CContainer fluid style={ftr2_tb}>
        <CRow>
        <CCol sm={5} md={4} lg={3} style={{textAlign:'center'}}>
        <CContainer fluid style={{height:'auto',padding:'1%',width:'100%',textAlign:'center'}}>
        <CImage fluid rounded thumbnail src="https://lifelinehealth.in/image/logo.jpg" width={150} height={150} />
        </CContainer>
        <CContainer fluid style={{height:'auto',padding:'1%',width:'100%',textAlign:'center'}}>
        <h6 style={{color:'#EE7214'}}>Dedicated to a healthy and hearty life!</h6>
          </CContainer>
          <CContainer fluid style={{height:'auto',padding:'1%',width:'100%',textAlign:'center'}}>
          <h6>Subscribe to our <FcNews />feed</h6>
          </CContainer>
          <CContainer fluid style={{height:'auto',padding:'1%',width:'100%',textAlign:'center'}}>
          <CFormInput style={{backgroundImage:'linear-gradient(90deg, #D2E9E9,#E3F4F4)',boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em'}} placeholder="Please enter your email" aria-label="Username" aria-describedby="addon-wrapping" type="text" />
          <br/>
          <CButton  size="md" style={{width:'99%',backgroundColor:"#7ED7C1"}}><MdOutlineUnsubscribe />Subscribe</CButton>
          </CContainer>
          <br/>
          <CContainer fluid style={{textAlign:'left',padding:'1%',textDecoration:'underline'}}>
          <h6><MdAddCall/> +91-999999</h6>
          <br/>
          <h6><MdOutlineEmail/> lifeline@gmail.com</h6>
          </CContainer>
        </CCol>
        <CCol sm={7} md={8} lg={9} style={{textAlign:'center'}}>
        <CListGroup>
  <CListGroupItem style={ftr3}>Patient Cure</CListGroupItem>
  <CListGroupItem style={ftr3}>Medical Department</CListGroupItem>
  <CListGroupItem style={ftr3}>Health Bulletin</CListGroupItem>
  <CListGroupItem style={ftr3}>Hospital Network</CListGroupItem>
  <CListGroupItem style={ftr3}>Research & Development</CListGroupItem>
  <CListGroupItem style={ftr3}>Connect with Us</CListGroupItem>
  <CListGroupItem style={ftr3}>Job Portal</CListGroupItem>
  <CListGroupItem style={ftr3}>Latest Facilities</CListGroupItem>
  <CListGroupItem style={ftr3}>Hire Ambulance</CListGroupItem>
  <CListGroupItem style={ftr3}>Home Care</CListGroupItem>
  <CListGroupItem style={ftr3}>Tie Up</CListGroupItem>
</CListGroup>
        </CCol>
        </CRow>
      </CContainer>
        <CContainer fluid style={ftr_tb}>
        <CRow>
        <CCol sm={4} md={4} lg={4}>
        <CNav as="nav">
 <CNavLink href="#" style={header_c1}><TiSocialFacebookCircular /></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialInstagramCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialLinkedinCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiTwitterXFill/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiYoutubeLine /></CNavLink>
</CNav>
        </CCol>
          <CCol sm={4} md={4} lg={4}>
  <div>
    <CLink href="https://coreui.io">@ Copyright 2024</CLink>
    <span>&copy; Lifeline Multi-Speciality.</span>
 
    <span style={{color:'#005B41'}}>All rights reserved.</span>
    
  </div>
  </CCol>
  <CCol>
  <CNav as="nav">
 <CNavLink href="#" style={header_c1}><MdOutlinePrivacyTip/>Privacy Code</CNavLink>
 <CNavLink href="#" style={header_c1}><MdContactSupport/>Contact</CNavLink>
 <CNavLink href="#" style={header_c1}><MdOutlineAddBusiness/>Clause</CNavLink>
 <CNavLink href="#" style={header_c1}><MdOutlineFeedback/>Feedback</CNavLink>
 </CNav>
  </CCol>
  </CRow>
  </CContainer>
</CFooter>
<CContainer id="cht_i" fluid style={cht_tb} onMouseOver={cht_anim} onMouseOut={cht_anim2}>
 <CRow>
  <CCol  sm={3} md={3} lg={3} style={{textAlign:'left'}}>
<FaCommentMedical style={{color:'#C40C0C',fontSize:'30px'}} />
</CCol>
<CCol id="cht_bx"  sm={9} md={9} lg={9} style={{textAlign:'left'}}  onClick={cht_pl}>
<span>How may I help you?</span>
</CCol>
</CRow>
 </CContainer>
 <CContainer id="cht_bdy" style={cht_mn_tb}>
 <CBadge color="danger" position="top-end" shape="rounded-pill" onClick={cht_cls} style={{cursor:'pointer'}}>
 <FaRegWindowClose />
 </CBadge>
 <CListGroup>
 <CListGroupItem onClick={m1} as="button" style={cht_lst}><FaCalendarCheck />Fix-Appointment</CListGroupItem>
 <CListGroupItem onClick={m1} as="button" style={cht_lst}><BsTelephonePlusFill/>Tele-Medicine</CListGroupItem>
 <CListGroupItem  onClick={m1} as="button" style={cht_lst}><MdVideoChat/>Video-Call</CListGroupItem>
<CListGroupItem  onClick={m1} as="button" style={cht_lst}><FaAmbulance/>Book-Ambulance</CListGroupItem>
<CListGroupItem  onClick={m1} as="button" style={cht_lst}><RiCustomerService2Fill/>Customer-Care</CListGroupItem>
<CListGroupItem  onClick={m1} as="button" style={cht_lst}><FaBedPulse/>Bed-Availibility</CListGroupItem>
</CListGroup>

</CContainer>
      </CContainer>
    )}
     {isMobile && (
        <CContainer  fluid>
      <CHeader  style={header_style1}>
      <CContainer fluid>
        <CRow>
        <CCol sm={12} md={12} lg={12}>
        <CNav as="nav" style={header_c1}>
 <CNavLink href="#" style={header_c1}><TiSocialFacebookCircular /></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialInstagramCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialLinkedinCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiTwitterXFill/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiYoutubeLine /></CNavLink>
<CNavLink> <CDropdown style={{right:'0%'}}>
  <CDropdownToggle href="#" color="#4CCD99"><RxHamburgerMenu/></CDropdownToggle>
  <CDropdownMenu>
  <CDropdownItem href="#"><MdOutlinePrivacyTip/>Privacy Code</CDropdownItem>
    <CDropdownItem href="#"><MdContactSupport/>Contact</CDropdownItem>
    <CDropdownItem href="#"><MdOutlineAddBusiness/>Clause</CDropdownItem>
    <CDropdownItem href="#"><MdOutlineFeedback/>Feedback</CDropdownItem>
  </CDropdownMenu>
</CDropdown></CNavLink>
</CNav>
        </CCol>
       
        
</CRow>

</CContainer>

      </CHeader>
      <CContainer fluid style={hdr2}>
      <CRow>
      <CCol sm={12} md={12} lg={12} style={{textAlign:'left'}}>
      <CInputGroup className="flex-nowrap">
  <CFormInput style={{backgroundImage:'linear-gradient(90deg, #D2E9E9,#E3F4F4)',boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em'}} placeholder="Search Doctor or Hospital or Procedures or Manage-Booking or Hire-Ambulance" aria-label="Username" aria-describedby="addon-wrapping" type="search" />
  <CInputGroupText id="addon-wrapping" style={{backgroundImage:'linear-gradient(0deg, #495464,#495464)',color:'white'}}><FaSearchPlus/></CInputGroupText>
</CInputGroup>
      
      </CCol>
      <CCol sm={12} md={12} lg={12} style={{textAlign:'center',padding:'1%'}}>
      <CBadge textBgColor="light"><MdOutlineEmergency/>Emergency</CBadge><CButton color="primary" variant="outline" size="sm" style={{backgroundImage:'linear-gradient(90deg, #D2E9E9,#E3F4F4)'}}>9999<MdAddCall/></CButton>
      <CBadge textBgColor="light"><FaHandHoldingHeart/>Lifeline</CBadge><CButton color="primary" variant="outline" size="sm"  style={{backgroundImage:'linear-gradient(90deg, #D2E9E9,#E3F4F4)'}}>9999<MdAddCall/></CButton>
      </CCol>
      </CRow>
      </CContainer>
      <CContainer fluid style={{paddingLeft:'86%'}}>
      <CDropdown>
  <CDropdownToggle color="secondary"><MdFilterList/></CDropdownToggle>
  <CDropdownMenu style={{fontSize:'2.5vw'}}>
    <CDropdownItem as="button" >Patient-Cure<FaCaretRight/></CDropdownItem>
    
    <CDropdownItem as="button">Medical-Departments<FaCaretRight/></CDropdownItem>
    
    <CDropdownItem as="button">Health-Bulletin<FaCaretRight/></CDropdownItem>
    
    <CDropdownItem as="button">Hospital-Network<FaCaretRight/></CDropdownItem>
    
    <CDropdownItem as="button">Research & Development<FaCaretRight/></CDropdownItem>
    
    <CDropdownItem as="button">Connect-with Us<FaCaretRight/></CDropdownItem>
    
    
    <CDropdownItem as="button">Job-Portal<FaCaretRight/></CDropdownItem>
    
    <CDropdownItem as="button">Latest-Facilities<FaCaretRight/></CDropdownItem>
    
    <CDropdownItem as="button">Hire-Ambulance<FaCaretRight/></CDropdownItem>
    
    <CDropdownItem as="button">Home-Care<FaCaretRight/></CDropdownItem>
    
    
    <CDropdownItem as="button">Tie-Ups<FaCaretRight/></CDropdownItem>
    
  </CDropdownMenu>
</CDropdown>
      </CContainer>
      <CContainer fluid style={{padding:'0.5%', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
       <CRow>
         <CCol sm={12} md={12} lg={12}>
         <CCarousel  controls indicators dark>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i4.jpeg"} alt="slide 1" style={sldr} />
    <CCarouselCaption  style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Premier Multi Speciality Hospital</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i5.jpeg"} alt="slide 2" style={sldr} />
    <CCarouselCaption style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>State of Art Facilities</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i6.jpeg"} alt="slide 3" style={sldr} />
    <CCarouselCaption style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Cost effective Health-Care</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i7.jpeg"} alt="slide 4" style={sldr} />
    <CCarouselCaption style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Expert Medical Advice</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i8.jpeg"} alt="slide 5" style={sldr} />
    <CCarouselCaption style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Maternity and Neonatal Care</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i9.jpeg"} alt="slide 6" style={sldr} />
    <CCarouselCaption style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Emergency Department (ED)</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i10.jpeg"} alt="slide 7" style={sldr} />
    <CCarouselCaption style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Inpatient and Outpatient Services</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i11.jpeg"} alt="slide 8" style={sldr} />
    <CCarouselCaption style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Intensive Care Unit (ICU)</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i12.jpeg"} alt="slide 9" style={sldr} />
    <CCarouselCaption style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Operating Rooms (OT)</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i13.jpeg"} alt="slide 10" style={sldr} />
    <CCarouselCaption style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Specialty Departments</h5>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://lifelinehealth.in/image/i14.jpeg"} alt="slide 11" style={sldr} />
    <CCarouselCaption style={{color:'#A0153E',fontWeight:'bold',textShadow:'0 0 7px #fff,0 0 10px #fff, 0 0 21px #fff,0 0 42px #0fa,0 0 82px #0fa,0 0 92px #0fa,0 0 102px #0fa,0 0 151px #0fa'}}>
      <h5>Rehabilitation Services</h5>
    </CCarouselCaption>
  </CCarouselItem>
</CCarousel>
         </CCol>
       </CRow>
      </CContainer>
      <CContainer fluid style={{padding:'1%',paddingLeft:'12.6%',paddingRight:'25%'}}>
       <CRow>
       <CCol lg={12} xl={12} xxl={12}>
       <CListGroup className="xm-4" layout={`horizontal`}>
       <CListGroupItem style={{border:'none'}}>
       <CWidgetStatsA
      className="sm-4"
      style={{backgroundImage:'linear-gradient(90deg, #F6F5F2,#F0EBE3,#F3D0D7,#FFEFEF)',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}
      value={
        <>
        <CContainer fluid style={{fontSize:'0.63em',textAlign:'center'}}>
        <GrScheduleNew /><br/>
          Book<br/>Appointment
          </CContainer>
        </>
      }
      />
       </CListGroupItem>
       <CListGroupItem style={{border:'none'}}>
       <CWidgetStatsA
      className="sm-4"
      style={{backgroundImage:'linear-gradient(90deg, #F6F5F2,#F0EBE3,#F3D0D7,#FFEFEF)',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}
      value={
        <>
        <CContainer fluid style={{fontSize:'0.63em',textAlign:'center'}}>
        <GiHealthPotion /><br/>
          Health<br/>Checkup
          </CContainer>
        </>
      }
      />
       </CListGroupItem>
      
       </CListGroup>
       </CCol>
        </CRow>
        <CRow>
          <CCol lg={12} xl={12} xxl={12}>
          <CListGroup className="sm-4" layout={`horizontal`}>
          <CListGroupItem style={{border:'none'}}>
          <CWidgetStatsA
      className="sm-4"
      style={{backgroundImage:'linear-gradient(90deg, #F6F5F2,#F0EBE3,#F3D0D7,#FFEFEF)',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}
      value={
        <>
        <CContainer fluid style={{fontSize:'0.63em',textAlign:'center'}}>
        <MdOnlinePrediction /><br/>
          Online<br/>Consultancy
          </CContainer>
        </>
      }
      />
          </CListGroupItem>
          <CListGroupItem style={{border:'none'}}>
          <CWidgetStatsA
      className="sm-4"
      style={{backgroundImage:'linear-gradient(90deg, #F6F5F2,#F0EBE3,#F3D0D7,#FFEFEF)',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}
      value={
        <>
        <CContainer fluid style={{fontSize:'0.63em',textAlign:'center'}}>
        <MdLocalPharmacy /><br/>
          Purchase<br/>Pharmacy
          </CContainer>
        </>
      }
      />
          </CListGroupItem>
          </CListGroup>
          </CCol>
          </CRow>
     </CContainer>
     <CContainer fluid style={{height:'auto',width:'100%',padding:'1%',boxShadow:'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset'}}>
       <CRow>
           <CCol sm={12} md={12} lg={12} style={{fontSize:'0.90em',textAlign:'center'}}>
           <h4><span style={{backgroundImage:'linear-gradient(90deg, #E3F6FF,#E3F6FF)'}}>Nurturing Health Care</span></h4>
           </CCol>
         </CRow>
         <CContainer style={{padding:'1%',paddingLeft:'10%'}}>
         <CListGroup className="sm-4" layout={`horizontal`}>
         <CListGroupItem className="tzd" style={mnu_midd1} onClick={zz1} onMouseOver={mo1} onMouseOut={om1}><FaHandHoldingMedical style={{color:'#C40C0C'}}/> Departments</CListGroupItem>
         <CListGroupItem className="tzd" style={mnu_midd2} onClick={zz2} onMouseOver={mo2} onMouseOut={om2}><FaHospitalAlt style={{color:'#C40C0C'}}/> Facilities</CListGroupItem>
         <CListGroupItem className="tzd" style={mnu_midd3} onClick={zz3} onMouseOver={mo3} onMouseOut={om3}><FaUserDoctor style={{color:'#C40C0C'}}/> Faculty</CListGroupItem>
         </CListGroup>
         </CContainer>
         <CContainer fluid className="tzdd" style={md_bdy1}>
         <CRow>
           <CCol  xs={12}>
           <CImage  fluid rounded src="https://lifelinehealth.in/image/department.jpg"/>
           </CCol>
           <CCol  xs={12}>
           <CContainer fluid style={{padding:'1%'}}>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill"> Obstetrics &<br/> Gynaecology</CBadge>
           </CCol>
          </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill"> Orthopedics &<br/> spine</CBadge> 
           </CCol>
          
          
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
              <CCol   xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">General Surgery</CBadge>
           </CCol>
            </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">Gastroenterology</CBadge>
           </CCol>
           
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">Pediatrics</CBadge>
           </CCol>
                    </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">ENT</CBadge>
           </CCol>
                    </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">Neurology</CBadge>
           </CCol>
                    </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">Urology</CBadge>
           </CCol>
                    </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">Oncology</CBadge>
           </CCol>
                    </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">Cardiology</CBadge>
           </CCol>
                    </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">Plastic Surgery</CBadge>
           </CCol>
                    </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">Dental</CBadge>
           </CCol>
                    </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">Psychology</CBadge>
           </CCol>
                    </CRow>
           </CContainer>
           </CContainer>
           </CCol>
         </CRow>
         </CContainer>
      <CContainer fluid className="tzdd" style={md_bdy2}>
      <CRow>
           <CCol xs={12}>
           <CImage  fluid rounded src="https://lifelinehealth.in/image/facility.jpg"/>
           </CCol>
           <CCol xs={12}>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">ICU</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">NICU</CBadge>
           </CCol>
           
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">Ventilator</CBadge>
           </CCol>
          
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">Pathology</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">Physiotherapy</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">Dietitians</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">X-Ray</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">ECG</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">Pharmacy</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'3%'}}>
           <CRow>
           <CCol  xs={12}>
           <CBadge style={md_bddg} shape="rounded-pill">Ultrasound</CBadge>
           </CCol>
           </CRow>
           </CContainer>
           </CCol>
           </CRow>
      </CContainer>
      <CContainer fluid className="tzdd" style={md_bdy3}>
      <CRow>
         
           <CCol xs={12}>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol  xs={12}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Sashank Nigam
            </CBadge>
            <br/>
            <CBadge style={md_bddg2} shape="rounded-pill">
           MS,DNB,MRCS,MNAMS
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bddg3}>Fellowship, Surgical Oncology,</CBadge>
            
            <CBadge style={md_bddg3}>Oncoplastic Breast Surgery</CBadge>
            
            </CListGroupItem>
              </CListGroup>
           </CCol>
          
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol  xs={12}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Dharamvir Singh
            </CBadge>
            <br/>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS,MS,MCH(Urology)
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bddg3}>Urologist,</CBadge>
            <CBadge style={md_bddg3}>Laproscopic,</CBadge>
            <CBadge style={md_bddg3}>Transplant Surgeon</CBadge>
            </CListGroupItem>
              </CListGroup>
           </CCol>
           
          </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol  xs={12}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Abhishek Verma
            </CBadge>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MS(General Surgeon),FIAGES
            </CBadge>
           </CCol>
          
           
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol xs={12}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Rohit Saxena
            </CBadge>
            <br/>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS,MS(Opthalmologist)
            </CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol xs={12}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Nitesh Singh Sodi
            </CBadge>
            <br/>
            <CBadge style={md_bddg2} shape="rounded-pill">
          Psychology D(Honors)
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bddg3}>Neuro-psycologist</CBadge>
            
            <CBadge style={md_bddg3}>Counsellor & Psycotherapist</CBadge>
            
            </CListGroupItem>
              </CListGroup>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol xs={12}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Harish Kumar Singh
            </CBadge>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS,MD
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bddg3}>Physician</CBadge>
            </CListGroupItem>
              </CListGroup>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol xs={12}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Pranjal Saxena
            </CBadge>
            <br/>
            <CBadge style={md_bddg2} shape="rounded-pill">
          BDS(Dental Surgeon)
            </CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol xs={12}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Harsh Vardhan
            </CBadge>
            <br/>
            <CBadge style={md_bddg2} shape="rounded-pill">
          BDS,Fage(Dental Surgeon)
            </CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol xs={12}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Pankaj Pathak
            </CBadge>
            <br/>
            <CBadge style={md_bddg2} shape="rounded-pill">
         MDS(Oro-Maxicofasical)
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bddg3}>Consultant Oro</CBadge>
            
            <CBadge style={md_bddg3}>& Facial Trauma</CBadge>
            
            </CListGroupItem>
              </CListGroup>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol xs={12}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Sudhanshu Verma
            </CBadge>
            <br/>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS(D.Cardiology)
            </CBadge>
          
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol xs={12}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_fem.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Ankita Singh
            </CBadge>
            <br/>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS,FIGO(Gynecologist)
            </CBadge>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol xs={12}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_fem.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Nilima
            </CBadge>
            <br/>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS,MS
            </CBadge>
            <CListGroup>
            <CListGroupItem style={{border:'none'}}>
            <CBadge style={md_bddg3}>Obstetrics</CBadge>
            
            <CBadge style={md_bddg3}>& Gynecologist</CBadge>
            
            </CListGroupItem>
              </CListGroup>
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol xs={12}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Sharad Kumar Singh
            </CBadge>
            <br/>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS,MD(Pediatrics)
            </CBadge>
          
           </CCol>
           </CRow>
           </CContainer>
           <CContainer fluid style={{padding:'1%'}}>
           <CRow>
           <CCol xs={12}>
           <CAvatar  shape="rounded-1"  src={"https://lifelinehealth.in/image/doc_male.png"} />
            <CBadge style={md_bddg} shape="rounded-pill">
            Dr. Arshad Khalid
            </CBadge>
            <br/>
            <CBadge style={md_bddg2} shape="rounded-pill">
          MBBS,MS(Anaesthosiology)
            </CBadge>
           </CCol>
           </CRow>
           </CContainer>
          
           </CCol>
           </CRow>
      </CContainer>
      
      </CContainer>
     <CFooter>
     <CContainer fluid style={ftr2_tbl}>
        <CRow>
        <CCol sm={12} style={{textAlign:'center'}}>
        <CContainer fluid style={{height:'auto',padding:'1%',width:'100%',textAlign:'center'}}>
        <CImage fluid rounded thumbnail src="https://lifelinehealth.in/image/logo.jpg" width={150} height={150} />
        </CContainer>
        <CContainer fluid style={{height:'auto',padding:'1%',width:'100%',textAlign:'center'}}>
        <h6 style={{color:'#EE7214'}}>Dedicated to a healthy and hearty life!</h6>
          </CContainer>
          <CContainer fluid style={{height:'auto',padding:'1%',width:'100%',textAlign:'center'}}>
          <h6>Subscribe to our <FcNews />feed</h6>
          </CContainer>
          <CContainer fluid style={{height:'auto',padding:'1%',width:'100%',textAlign:'center'}}>
          <CFormInput style={{backgroundImage:'linear-gradient(90deg, #D2E9E9,#E3F4F4)',boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em'}} placeholder="Please enter your email" aria-label="Username" aria-describedby="addon-wrapping" type="text" />
          <br/>
          <CButton  size="sm" style={{width:'99%',backgroundColor:"#7ED7C1"}}><MdOutlineUnsubscribe />Subscribe</CButton>
          </CContainer>
          <br/>
          <CContainer fluid style={{textAlign:'center',padding:'1%',textDecoration:'underline',borderBottom:'1px solid white'}}>
          <CNav as="nav">
 <CNavLink href="#" style={ftr3}><h6><MdAddCall/> +91-999999</h6></CNavLink>
 <CNavLink href="#" style={ftr3}><h6><MdOutlineEmail/> lifeline@gmail.com</h6></CNavLink>
</CNav>
        </CContainer>
        <CContainer fluid  style={{height:'auto',padding:'1%',width:'100%',textAlign:'center'}}>
        <CNav>
  <CNavLink style={ftr3}>Patient Cure</CNavLink>
  <CNavLink style={ftr3}>Medical Department</CNavLink>
  <CNavLink style={ftr3}>Health Bulletin</CNavLink>
  <CNavLink style={ftr3}>Hospital Network</CNavLink>
  <CNavLink style={ftr3}>Research & Development</CNavLink>
  <CNavLink style={ftr3}>Connect with Us</CNavLink>
  <CNavLink style={ftr3}>Job Portal</CNavLink>
  <CNavLink style={ftr3}>Latest Facilities</CNavLink>
  <CNavLink style={ftr3}>Hire Ambulance</CNavLink>
  <CNavLink style={ftr3}>Home Care</CNavLink>
  <CNavLink style={ftr3}>Tie Up</CNavLink>
</CNav>
        </CContainer>
        </CCol>
        </CRow>
      </CContainer>
      <CContainer fluid style={ftr_tb}>
        <CRow>
        <CCol sm={12} style={{textAlign:'center'}}>
        <CNav as="nav">
 <CNavLink href="#" style={header_c1}><TiSocialFacebookCircular /></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialInstagramCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialLinkedinCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiTwitterXFill/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiYoutubeLine /></CNavLink>
 </CNav>
 <CNav style={{borderTop:'1px solid black'}}>
 <CNavLink href="#" style={header_c1}><CLink href="https://coreui.io">@ Copyright 2024</CLink><span>&copy; Lifeline Multi-Speciality.</span>
 
 <span style={{color:'#005B41'}}>All rights reserved.</span></CNavLink>
 </CNav>
<CNav as="nav" style={{borderTop:'1px solid black'}}>
 <CNavLink href="#" style={header_c1}><MdOutlinePrivacyTip/>Privacy Code</CNavLink>
 <CNavLink href="#" style={header_c1}><MdContactSupport/>Contact</CNavLink>
 <CNavLink href="#" style={header_c1}><MdOutlineAddBusiness/>Clause</CNavLink>
 <CNavLink href="#" style={header_c1}><MdOutlineFeedback/>Feedback</CNavLink>
 </CNav>
        </CCol>
          </CRow>
          </CContainer>
       </CFooter>
       <CContainer id="cht_i" fluid style={cht_tb} onMouseOver={cht_anim} onMouseOut={cht_anim2}>
 <CRow>
  <CCol  xs={12} style={{textAlign:'left'}}>
<FaCommentMedical style={{color:'#C40C0C',fontSize:'30px'}} />
</CCol>
<CCol id="cht_bx"  xs={12} style={{textAlign:'left'}}  onClick={cht_pl}>
<span>How may I help you?</span>
</CCol>
</CRow>
 </CContainer>
 <CContainer id="cht_bdy" style={cht_mn_mb}>
 <CBadge color="danger" position="top-end" shape="rounded-pill" onClick={cht_cls} style={{cursor:'pointer'}}>
 <FaRegWindowClose />
 </CBadge>
 <CListGroup>
 <CListGroupItem onClick={m1} as="button" style={cht_lst}><FaCalendarCheck />Fix-Appointment</CListGroupItem>
 <CListGroupItem onClick={m1} as="button" style={cht_lst}><BsTelephonePlusFill/>Tele-Medicine</CListGroupItem>
 <CListGroupItem  onClick={m1} as="button" style={cht_lst}><MdVideoChat/>Video-Call</CListGroupItem>
<CListGroupItem  onClick={m1} as="button" style={cht_lst}><FaAmbulance/>Book-Ambulance</CListGroupItem>
<CListGroupItem  onClick={m1} as="button" style={cht_lst}><RiCustomerService2Fill/>Customer-Care</CListGroupItem>
<CListGroupItem  onClick={m1} as="button" style={cht_lst}><FaBedPulse/>Bed-Availibility</CListGroupItem>
</CListGroup>

</CContainer>
      </CContainer>
    )}
    </div>
  );
}

export default App;
     
